import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  MenuItem,
  Pagination,
} from "@mui/material";
import { Div } from "..";
import { loadAtendimentosSetorList } from "./action";
import DetailsScreen from "./Details/DetailsScreen";

const AtendimentosSetor = () => {
  const dispatch = useDispatch();
  const atendimentosSetor = useSelector(
    (state) => state.atendimentosSetor.atendimentosList
  );
  const userData = useSelector((state) => state.appReducer.user);
  const showDetails = useSelector(
    (state) => state.atendimentosSetor.showDetails
  );
  const atendimentosSetorDetails = useSelector(
    (state) => state.atendimentosSetor.atendimentosSetorDetails
  );

  const [openDetails, setOpenDetails] = useState(false);
  const [selectedAtendimento, setSelectedAtendimento] = useState(null);
  const [filter, setFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [assuntosOptions, setAssuntosOptions] = useState([]);
  const [searchSolicitante, setSearchSolicitante] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const isAdm = userData.accessControl?.adm;

  useEffect(() => {
    if (!atendimentosSetor || atendimentosSetor.length === 0) {
      dispatch(loadAtendimentosSetorList());
    }
    resultsetor();
  }, [atendimentosSetor, dispatch]);

  const resultsetor = () => {
    if (userData.orgao === "Cras") {
      setAssuntosOptions(assuntosCrasOptions);
    } else if (userData.orgao === "Creas") {
      setAssuntosOptions(assuntosCreasOptions);
    } else if (userData.orgao === "Família Acolhedora") {
      setAssuntosOptions(assuntosFamiliaAcolhedoraOptions);
    } else {
      setAssuntosOptions(assuntosHabitacaoOptions);
    }
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };

  const assuntosCrasOptions = [
    "Oficinas",
    "SCFV ( Serviço de convivência e fortalecimento de vinculos)",
    "Cadastro único",
    "Beneficio Eventual (Funeral)",
    "Beneficio Eventual (Alimentação)",
    "Beneficio Eventual (Auxilio Natalidade)",
    "Beneficio Eventual(Auxilio Vulnerabilidade)",
    "Encaminhamento BPC",
  ];
  const assuntosCreasOptions = [
    "Atendimento Equipe Técnica",
    "Beneficio Eventual (Funeral)",
    "Beneficio Eventual (Alimentação)",
    "Beneficio Eventual (Auxilio Natalidade)",
    "Beneficio Eventual(Auxilio Vulnerabilidade)",
    "Encaminhamento BPC",
  ];
  const assuntosFamiliaAcolhedoraOptions = [
    "Atendimento Equipe Técnica",
    "Beneficio Eventual (Funeral)",
    "Beneficio Eventual (Alimentação)",
    "Beneficio Eventual (Auxilio Natalidade)",
    "Beneficio Eventual(Auxilio Vulnerabilidade)",
    "Encaminhamento BPC",
  ];
  const assuntosHabitacaoOptions = [
    "Axulio Reforma Moradia",
    "Programa Habitacional",
  ];

  const handleOpenDetails = (atendimento) => {
    setSelectedAtendimento(atendimento);
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
    setSelectedAtendimento(null);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const filteredAtendimentosSetor = atendimentosSetor.filter((atendimento) => {
    const belongsToUserSetor = atendimento.setor === userData.orgao || isAdm;
    const assuntoMatchesFilter = filter === "" || atendimento.assunto === filter;
    const solicitanteMatchesSearch = !searchSolicitante || atendimento.solicitante.toLowerCase().includes(searchSolicitante.toLowerCase());
    const atendimentoData = new Date(atendimento.dataEnvio);
    const formattedDataEnvio = atendimentoData.toLocaleString(); // Converte a data para uma string legível
    const atendimentoDate = formattedDataEnvio.split(" ")[0]; // Obtém apenas a parte da data
    const dateMatchesFilter =
      (!dataInicio || atendimentoDate >= dataInicio) &&
      (!dataFim || atendimentoDate <= dataFim);
    return belongsToUserSetor && assuntoMatchesFilter && solicitanteMatchesSearch && dateMatchesFilter;
  });

  // Paginação dos atendimentos
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentAtendimentosSetor = filteredAtendimentosSetor.slice(startIndex, startIndex + itemsPerPage);

  return (
    <Div className="aviso-container">
      <Div className="welcome-container">
        <Div elevation={6} className="aviso-paper">
          <Typography variant="h4" className="text-center mb-4">
            Atendimento do Setor - {userData.orgao}
          </Typography>
        </Div>
      </Div>
      {/* Adicionar seção de filtro */}
      <Grid container spacing={2} justifyContent="center" alignItems="center" display="flex">
        <Grid item xs={6} sm={5}>
          <TextField
            fullWidth
            label="Buscar por solicitante"
            variant="outlined"
            value={searchSolicitante}
            onChange={(e) => setSearchSolicitante(e.target.value)}
          />
        </Grid>

        {!isAdm && <Grid item xs={3} sm={3}>
          <TextField
            select
            fullWidth
            label="Filtrar por assunto"
            variant="outlined"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <MenuItem value="">Todos</MenuItem>
            {assuntosOptions.map((assuntoOption) => (
              <MenuItem key={assuntoOption} value={assuntoOption}>
                {assuntoOption}
              </MenuItem>
            ))}
          </TextField>
        </Grid>}

        <Grid item xs={3} sm={1}>
          <TextField
            fullWidth
            label="Data de Início"
            type="date"
            variant="outlined"
            value={dataInicio}
            onChange={(e) => setDataInicio(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={3} sm={1}>
          <TextField
            fullWidth
            label="Data de Fim"
            type="date"
            variant="outlined"
            value={dataFim}
            onChange={(e) => setDataFim(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      {/* Fim da seção de filtro */}
      <Container>
        <Grid
          container
          spacing={4}
          className="aviso-grid"
          alignItems="center"
          justifyContent="center"
        >
          {currentAtendimentosSetor &&
            currentAtendimentosSetor
              .filter((atendimento) => atendimento.assunto.includes(searchTerm))
              .map((atendimento, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={index}
                  className="aviso-item-grid"
                >
                  <Card elevation={3}>
                    <CardContent>
                      <Typography variant="subtitle1" color="textSecondary">
                        <strong>Assunto: </strong> {atendimento.assunto}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        <strong>Setor: </strong> {atendimento.setor}
                      </Typography>
                      <Box mt={2}>
                        <Typography variant="body1">
                          <strong>Solicitante:</strong>{" "}
                          {atendimento.solicitante}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Contato:</strong> {atendimento.contato}
                        </Typography>
                        <Typography variant="body1" mt={2}>
                          <strong>Descrição:</strong>
                        </Typography>
                        <Typography
                          style={{ height: '100px' }}
                          variant="body2"
                          dangerouslySetInnerHTML={{
                            __html: truncateDescription(
                              atendimento.descricao,
                              300
                            ),
                          }}
                        />
                      </Box>
                      <Button
                        variant="contained"
                        onClick={() => handleOpenDetails(atendimento)}
                      >
                        Mais detalhes
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
       

        </Grid>
        <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={Math.ceil((atendimentosSetor ? atendimentosSetor.length : 0) / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
            <Box mb={11} />
      </Container>

      {selectedAtendimento && (
        <Div className="details-screen-overlay">
          <DetailsScreen
            onClose={handleCloseDetails}
            atendimentosSetorData={selectedAtendimento}
          />
        </Div>
      )}
    </Div>
  );
};

export default AtendimentosSetor;
import { SERVICOS_CHANGE_VALUE } from "../../constants/actionTypes";
import { firebase, getReducer } from "../../utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const addHistoryEntry = (data, userName, action) => {
    const currentDate = new Date().toISOString();
    return {
      ...data,
      modifiedBy: userName,
      modificationDate: currentDate,
      action
    };
  };

export function changeVisibleDetailsDialog(show, data= null) {
    return dispatch => { 
        dispatch({ type: SERVICOS_CHANGE_VALUE, property: 'showDetails', payload: show })
        if(data) {
            dispatch({ type: SERVICOS_CHANGE_VALUE, property: 'servicosDetails', payload: data})
            dispatch({ type: SERVICOS_CHANGE_VALUE, property: 'edit', payload: true})
        }
        if(!show) setTimeout(() => { dispatch({ type: SERVICOS_CHANGE_VALUE, property: 'servicosDetails', payload: {key: '', name: '', description: ''}}) }, 250)
    }}
    

export function loadServicosList() {
    return dispatch => {
        firebase.firestore.collection('servicos').get()
        .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            dispatch({ type: SERVICOS_CHANGE_VALUE, property: 'servicosList', payload: data})
        })
        .catch(error => {
            console.log("loadServicosList" ,error)
        })
    }
}

export function editServicosList(data, userName) {
    return dispatch => {
        const currentDate = new Date().toISOString();
        const updatedServicos = {
          ...data,
          modifiedBy: userName,
          modificationDate: currentDate
        };

        firebase.firestore.doc(`servicos/${data.key}`).update(updatedServicos)
        .then(() => {
            const historyData = addHistoryEntry(data, userName, 'Editou');
            firebase.firestore.collection(`servicos/${data.key}/history`).doc(currentDate).set(historyData);
    

            const servicosList = getReducer("servicos", "servicosList")
            let updateList = JSON.parse(JSON.stringify(servicosList))
            updateList = updateList.map(servicos =>  servicos.key === data.key ? data : servicos)
            dispatch({ type: SERVICOS_CHANGE_VALUE, property: 'servicosList', payload: updateList })
            dispatch(changeVisibleDetailsDialog(false))
        })
        .catch(error => {
            console.log("editServicosList", error)
        })
    }
}

export function deleteServicos(key) {
    return dispatch => {
        firebase.firestore.doc(`servicos/${key}`).delete()
        .then(() => {
            const servicosList = getReducer("servicos", "servicosList")
            let updateList = JSON.parse(JSON.stringify(servicosList))
            updateList = updateList.filter(servicos => servicos.key !== key)
            dispatch({ type: SERVICOS_CHANGE_VALUE, property: 'servicosList', payload: updateList })
        })
        .catch(error => {
            console.log("deleteServicos", error)
        })
    }
}

export function addServicos(data, userName) { 
    return (dispatch, getState) => {
        const refDoc = firebase.firestore.collection('servicos').doc(); 
        const newDocId = refDoc.id; 
        
        const name = data.name !== undefined ? data.name : 'defaultName';
        const description = data.description !== undefined ? data.description : 'defaultDescription';
        
        const dataServicos = {
            ...data,
            key: newDocId,
            name: name,
            description: description,
            createdBy: userName,
            creationDate: new Date().toISOString()
        };

        refDoc.set(dataServicos)
            .then(() => {
                const currentServicosList = getState().servicos.servicosList; // Obtém a lista atual de serviços do estado Redux
                const updatedServicosList = [...currentServicosList, dataServicos]; // Adiciona o novo tópico à lista atual
                dispatch({ type: SERVICOS_CHANGE_VALUE, property: 'servicosList', payload: updatedServicosList }); // Atualiza o estado Redux com a nova lista

                const historyData = addHistoryEntry(dataServicos, userName, 'Adicionou');
                firebase.firestore.collection(`servicos/${newDocId}/history`).doc(dataServicos.creationDate).set(historyData);
        

                dispatch(changeVisibleDetailsDialog(false)); // Fecha a tela de detalhes
                toast.success('Nova serviços adicionado com sucesso!');

            })
            .catch(error => {
                console.log("addServicos", error);
                toast.error('Erro ao adicionar novo serviço.');

            });
    };
}

export const loadServicosHistory = (key) => async (dispatch) => {
    try {
      const historyRef = firebase.firestore.collection('servicos').doc(key).collection('history');
      const historySnapshot = await historyRef.orderBy('modificationDate', 'desc').get();
  
      const historyData = historySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      dispatch({
        type: 'LOAD_SERVICOS_HISTORY_SUCCESS',
        payload: historyData
      });
    } catch (error) {
      console.error("Error loading history: ", error);
      dispatch({
        type: 'LOAD_SERVICOS_HISTORY_FAILURE',
        payload: error
      });
    }
  };


import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { editAvisosList, addAvisos } from "../action";
import { Typography, Box, Grid, Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Div from "../../Div/Div";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import InfoIcon from '@mui/icons-material/Info';
import EventIcon from '@mui/icons-material/Event';

function DetailsScreen({ onClose, avisosData }) {
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("PriorityHighIcon");
  const [category, setCategory] = useState("Urgente");
  const [iconSelected, setIconSelected] = useState(false);
  const userData = useSelector((state) => state.appReducer.user);


  useEffect(() => {
    if (avisosData) {
      setDescription(avisosData.description || "");
      setName(avisosData.name || "");
      setCategory(avisosData.category || "Urgente");
      setIcon(getIconFromCategory(avisosData.category || "Urgente"));
      setIconSelected(true);
    }
  }, [avisosData]);

  const getIconFromCategory = (category) => {
    switch (category) {
      case "Urgente":
        return "PriorityHighIcon";
      case "Informativo":
        return "InfoIcon";
      case "Eventos":
        return "EventIcon";
      default:
        return "PriorityHighIcon";
    }
  };

  const getTextFromCategory = (category) => {
    switch (category) {
      case "Urgente":
        return "Urgente";
      case "Informativo":
        return "Informativo";
      case "Eventos":
        return "Eventos";
      default:
        return "Urgente";
    }
  };

  const handleSave = () => {
    const updatedAvisos = {
      ...avisosData,
      name: name,
      description: description,
      category: category,
      icon: icon
    };
  
    const userName = userData.name; // Supondo que o nome do usuário esteja disponível no estado global
  
    if (avisosData) {
      dispatch(editAvisosList(updatedAvisos, userName)); // Passando o nome do usuário como argumento
    } else {
      dispatch(addAvisos(updatedAvisos, userName)); // Passando o nome do usuário como argumento
    }
  
    onClose();
  };
  

  const handleClose = () => {
    onClose();
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeIcon = (category) => {
    setIcon(getIconFromCategory(category));
    setCategory(category);
    setIconSelected(true);
    handleCloseMenu();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const renderIcon = (icon) => {
    switch (icon) {
      case "PriorityHighIcon":
        return <PriorityHighIcon />;
      case "InfoIcon":
        return <InfoIcon />;
      case "EventIcon":
        return <EventIcon />;
      default:
        return <PriorityHighIcon />;
    }
  };

  const editorHeight = window.innerWidth < 600 ? "78%" : "95%";

  return (
    <Div
      style={{
        padding: "20px",
        backgroundColor: "#eaeaea",
        borderRadius: 5,
        width: "90%", // Alterado para ocupar 100% da largura
        position: "relative",
        zIndex: 1000,
      }}
    >
      <Div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ textAlign: "center", margin: "0" }}>
          {avisosData ? "Editar Aviso" : "Novo Aviso"}
        </h2>
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <TextField
            fullWidth
            label="Título do Aviso"
            value={name}
            disabled={!!avisosData}
            variant="outlined"
            onChange={handleChangeName}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              id="icon-label"
              aria-controls="icon-label"
              aria-haspopup="true"
              onClick={handleClick}
              startIcon={iconSelected ? renderIcon(icon) : null}
              endIcon={<ExpandMoreIcon />}
            >
              {iconSelected ? getTextFromCategory(category) : "Tipo de aviso"}
            </Button>

            <Menu
              id="icon-select"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              getContentAnchorEl={null}
              elevation={1}
              style={{ zIndex: 9999 }}
            >
              <MenuItem onClick={() => handleChangeIcon("Urgente")}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <PriorityHighIcon style={{ marginRight: 8 }} />
                  Urgente
                </div>
              </MenuItem>
              <MenuItem onClick={() => handleChangeIcon("Informativo")}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InfoIcon style={{ marginRight: 8 }} />
                  Informativo
                </div>
              </MenuItem>
              <MenuItem onClick={() => handleChangeIcon("Eventos")}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EventIcon style={{ marginRight: 8 }} />
                  Eventos
                </div>
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ height: "300px" }}>
          <ReactQuill
            theme="snow"
            style={{ height: editorHeight }}
            value={description}
            onChange={handleChangeDescription}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ size: [] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                ["link", "image", "video"],
                ["clean"],
              ],
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={!iconSelected || !description || !name}
            style={{ marginTop: "20px" }}
          >
            {avisosData ? "Salvar Alterações" : "Salvar"}
          </Button>
        </Grid>
      </Grid>
    </Div>
  );
}

DetailsScreen.propTypes = {
  onClose: PropTypes.func.isRequired,
  avisosData: PropTypes.object,
};

export default DetailsScreen;

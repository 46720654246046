import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Fab,
  Container,
  Box,
  Grid,
  IconButton,
  Pagination,
} from "@mui/material";
import { Div } from "..";
import DialogDelete from "./Details/DialogDelete";
import AddIcon from "@mui/icons-material/Add";
import DetailsScreen from "./Details/DetailsScreen";
import HistoryScreen from "./Details/HistoryScreen";
import { addAvisos, loadAvisosList, deleteAvisos, loadAvisosHistory } from "./action";
import "./Avisos.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import InfoIcon from "@mui/icons-material/Info";
import EventIcon from "@mui/icons-material/Event";
import HistoryIcon from "@mui/icons-material/History";
import { ToastContainer, toast } from 'react-toastify';

const getIconComponent = (iconClassName) => {
  switch (iconClassName) {
    case "PriorityHighIcon":
      return <PriorityHighIcon />;
    case "InfoIcon":
      return <InfoIcon />;
    case "EventIcon":
      return <EventIcon />;
    default:
      return null;
  }
};

const Avisos = () => {
  const dispatch = useDispatch();
  const avisos = useSelector((state) => state.avisos.avisosList);

  const [showDetails, setShowDetails] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedAvisos, setSelectedAvisos] = useState(null);
  const [historyAvisos, setHistoryAvisos] = useState(null);
  const [dele, setDele] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleDele = (avisos) => {
    setDele(true);
    setSelectedAvisos(avisos);
  };

  const handleDelete = () => {
    setDele(false);
    setSelectedAvisos(null);
    toast.success('Aviso deletado com sucesso!');
  };

  const deleteProduct = () => {
    dispatch(deleteAvisos(selectedAvisos.key));
    handleDelete();
    toast.info('Aviso deletado com sucesso!');
  };

  useEffect(() => {
    if (!avisos) {
      dispatch(loadAvisosList());
    }
  }, [avisos, dispatch]);

  const handleOpen = () => {
    setShowDetails(true);
    setSelectedAvisos(null);
  };

  const handleHistory = (aviso) => {
    setShowHistory(true);
    setHistoryAvisos(aviso);
    dispatch(loadAvisosHistory(aviso.key));
  };

  const handleEditAvisos = (aviso) => {
    setShowDetails(true);
    setSelectedAvisos(aviso);
  };

  const handleAddAvisos = (avisosData) => {
    const userName = userData ? userData.name : 'Guest';
    dispatch(addAvisos(avisosData, userName));
    setShowDetails(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const editorHeight = window.innerWidth < 600 ? "15%" : "24%";

  const userData = useSelector((state) => state.appReducer.user);
  const isAdm = userData?.accessControl?.adm || false;
  const readyOnly = userData?.accessControl?.readyOnly || false;
  const userName = userData?.name || 'Guest';

  const IconLegend = () => {
    return (
      <Box mt={2} justifyContent="end">
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <PriorityHighIcon />
          </Box>
          <Typography variant="body2" mr={2}>
            Urgente
          </Typography>
          <Box mr={1}>
            <InfoIcon />
          </Box>
          <Typography variant="body2" mr={2}>
            Informação
          </Typography>
          <Box mr={1}>
            <EventIcon />
          </Box>
          <Typography variant="body2">Evento</Typography>
        </Box>
      </Box>
    );
  };

  // Paginação dos avisos
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentAvisos = avisos ? avisos.slice(startIndex, startIndex + itemsPerPage) : [];

  return (
    <div className="aviso-container">
      <Div className="welcome-container">
        <Div elevation={6} className="aviso-paper">
          <Typography variant="h1" className="text-center mb-4">
            Quadro de Avisos
          </Typography>
        </Div>
      </Div>
      <Grid
        container
        justifyContent="end"
        style={{ marginRight: editorHeight }}
        marginBottom="15px"
      >
        <IconLegend />
      </Grid>
      <Container>
        <ToastContainer />
        {showDetails && (
          <Div className="details-screen-overlay">
            <DetailsScreen
              onClose={() => setShowDetails(false)}
              avisosData={selectedAvisos}
              onSave={handleAddAvisos}
            />
          </Div>
        )}
        {showHistory && (
          <Div className="details-screen-overlay">
            <HistoryScreen onClose={() => setShowHistory(false)} avisosData={historyAvisos}/>
          </Div>
        )}
        {!showDetails && !showHistory && (
          <>
            <Grid
              container
              spacing={4}
              className="aviso-grid"
              alignItems="center"
              justifyContent="center"
            >
              {currentAvisos &&
                currentAvisos.map((aviso, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={index}
                    className="aviso-item-grid"
                  >
                    <Div elevation={13} className="aviso-item">
                      <Grid container alignItems="center">
                        <Grid item xs={1} style={{ marginRight: '15px' }}>
                          {getIconComponent(aviso.icon)}
                        </Grid>
                        <Grid item xs={7}>
                          <Typography variant="h6">{aviso.name}</Typography>
                        </Grid>

                        {isAdm || readyOnly ? (
                          <Grid item xs={3} container justifyContent="flex-end">
                            <IconButton
                              onClick={() => handleEditAvisos(aviso)}
                              style={{ color: "black" }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDele(aviso)}
                              style={{ color: "black" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleHistory(aviso)}
                              style={{ color: "black" }}
                            >
                              <HistoryIcon />
                            </IconButton>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid sx={12}style={{ marginTop: "1.5rem" }}>
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: aviso.description,
                          }}
                        />
                      </Grid>
                    </Div>
                  </Grid>
                ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={Math.ceil((avisos ? avisos.length : 0) / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
            <Box mb={11} />
            <DialogDelete
              dele={dele}
              onClose={handleDelete}
              deleteAvisos={deleteProduct}
              avisos={selectedAvisos}
            />
            {isAdm || readyOnly ? (
              <Box className="fab-container">
                <Fab color="secondary" aria-label="add" onClick={handleOpen}>
                  <AddIcon />
                </Fab>
              </Box>
            ) : null}
          </>
        )}
      </Container>
    </div>
  );
};

export default Avisos;

import { ATENDIMENTOS_CHANGE_VALUE } from "../../constants/actionTypes";
import { firebase, getReducer } from "../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function changeVisibleDetailsDialog(show, data = null) {
  return (dispatch) => {
    dispatch({
      type: ATENDIMENTOS_CHANGE_VALUE,
      property: "showDetails",
      payload: show,
    });
    if (data) {
      dispatch({
        type: ATENDIMENTOS_CHANGE_VALUE,
        property: "atendimentosDetails",
        payload: data,
      });
      dispatch({
        type: ATENDIMENTOS_CHANGE_VALUE,
        property: "edit",
        payload: true,
      });
    }
    if (!show)
      setTimeout(() => {
        dispatch({
          type: ATENDIMENTOS_CHANGE_VALUE,
          property: "atendimentosDetails",
          payload: { key: "", name: "", description: "" },
        });
      }, 250);
  };
}

export function loadAtendimentosList() {
  return (dispatch) => {
    firebase.firestore
      .collection("atendimentos")
      .get()
      .then((snap) => {
        const data = snap.docs.map((doc) => doc.data());
        dispatch({
          type: ATENDIMENTOS_CHANGE_VALUE,
          property: "atendimentosList",
          payload: data,
        });
      })
      .catch((error) => {
      });
  };
}

export function editAtendimentosList(data) {
  return (dispatch) => {
    firebase.firestore
      .doc(`atendimentos/${data.key}`)
      .update(data)
      .then(() => {
        const atendimentosList = getReducer("atendimentos", "atendimentosList");
        let updateList = JSON.parse(JSON.stringify(atendimentosList));
        updateList = updateList.map((atendimentos) =>
          atendimentos.key === data.key ? data : atendimentos
        );
        dispatch({
          type: ATENDIMENTOS_CHANGE_VALUE,
          property: "atendimentosList",
          payload: updateList,
        });
        dispatch(changeVisibleDetailsDialog(false));
      })
      .catch((error) => {
        console.log("editAtendimentosList", error);
      });
  };
}

export function deleteAtendimentos(key) {
  return (dispatch) => {
    firebase.firestore
      .doc(`atendimentos/${key}`)
      .delete()
      .then(() => {
        const atendimentosList = getReducer("atendimentos", "atendimentosList");
        let updateList = JSON.parse(JSON.stringify(atendimentosList));
        updateList = updateList.filter(
          (atendimentos) => atendimentos.key !== key
        );
        dispatch({
          type: ATENDIMENTOS_CHANGE_VALUE,
          property: "atendimentosList",
          payload: updateList,
        });
      })
      .catch((error) => {
        console.log("deleteAtendimentos", error);
      });
  };
}

export function addAtendimentos(data) {
  return (dispatch, getState) => {
    const refDoc = firebase.firestore.collection("atendimentos").doc();
    const newDocId = refDoc.id;

    const setor = data.setor !== undefined ? data.setor : "defaultDescription";
    const assunto = data.assunto !== undefined ? data.assunto : "defaultName";
    const solicitante =
      data.solicitante !== undefined ? data.solicitante : "defaultDescription";
    const contato =
      data.contato !== undefined ? data.contato : "defaultDescription";
    const descricao =
      data.descricao !== undefined ? data.descricao : "defaultDescription";

      const dataAtual = new Date(); // Obtém a data e hora atuais
      const dia = String(dataAtual.getDate()).padStart(2, '0'); // Obtém o dia atual e adiciona um zero à esquerda, se necessário
      const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Obtém o mês atual e adiciona um zero à esquerda, se necessário
      const ano = dataAtual.getFullYear(); // Obtém o ano atual
      
      const dataFormatada = `${dia}/${mes}/${ano}`; // Formata a data no formato "dd/mm/aaaa"
      console.log(dataFormatada); // Saída: "03/06/2024" (para 3 de junho de 2024, por exemplo)
      
    const dataAtendimentos = {
      ...data,
      key: newDocId,
      setor: setor,
      assunto: assunto,
      solicitante: solicitante,
      contato: contato,
      descricao: descricao,
      dataEnvio: dataFormatada, // Adiciona a data de envio atual
    };

    // Função para remover tags HTML, mas manter o conteúdo das tags <p>
    const removeHtmlTags = (str) => {
      // Remove todas as tags, exceto o conteúdo das tags <p>
      return str
        .replace(/<\/?[^>]+(>|$)/g, function (match) {
          return match === "<p>" || match === "</p>" ? "\n" : ""; // Substitui <p> e </p> por nova linha
        })
        .replace(/\n\s*\n/g, "\n"); // Remove novas linhas duplicadas
    };

    refDoc
      .set(dataAtendimentos)
      .then(() => {
        const currentAtendimentosList =
          getState().atendimentos.atendimentosList; // Corrigido para acessar a propriedade correta do estado Redux
        const updatedAtendimentosList = [
          ...currentAtendimentosList,
          dataAtendimentos,
        ]; // Adiciona o novo atendimento à lista atual
        dispatch({
          type: ATENDIMENTOS_CHANGE_VALUE,
          property: "atendimentosList",
          payload: updatedAtendimentosList,
        }); // Atualiza o estado Redux com a nova lista
        dispatch(changeVisibleDetailsDialog(false)); // Fecha a tela de detalhes

        // Remover tags HTML do assunto e descrição
        const cleanDescricao = removeHtmlTags(descricao);

        // Construir a mensagem personalizada
        const message =
          "Olá! Gostaria de registrar um atendimento:\n\n" +
          "Setor: " +
          setor +
          "\n" +
          "Assunto: " +
          assunto +
          "\n" +
          "Descrição: " +
          cleanDescricao +
          "\n" +
          "Solicitante: " +
          solicitante +
          "\n" +
          "Contato: " +
          contato;

        let whatsappNumber = ""; // Número padrão
        if (setor === "Cras") {
          whatsappNumber = "5548996688446"; // Número alternativo para o setor "Cras"
        } else if (setor === "Creas") {
          whatsappNumber = "5548996688446"; // Número alternativo para o setor "Cras"
        } else if (setor === "Família Acolhedora") {
          whatsappNumber = "5548996688446"; // Número alternativo para o setor "Cras"
        } else {
          whatsappNumber = "5548996688446"; // Número alternativo para o setor "Cras"
        }

        const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;

        // Criar um elemento <a> para simular o clique
        const link = document.createElement("a");
        link.href = whatsappLink;
        link.target = "_blank";
        document.body.appendChild(link);

        // Simular o clique no link
        link.click();

        // Remover o elemento <a> depois que o link for aberto
        document.body.removeChild(link);

        // Abrir link do WhatsApp
        window.open(whatsappLink, "_blank"); // Abre o link em uma nova aba

        // Agendar a recarga da página após 1 segundo (1000 milissegundos)
        setTimeout(() => {
          // Definir o novo URL para a tela de perfil de usuário
          window.location.href = "/";
        }, 1000);
      })
      .catch((error) => {
        console.log("addAtendimentos", error);
      });
  };
}

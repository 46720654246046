import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector, useDispatch } from "react-redux";
import {
  changeVisibleDetailsDialog,
  editServicosList,
  addServicos,
} from "../action";
import Div from "../../Div/Div";
import "./DetailsScreen.css"; // Importe o arquivo CSS

function DetailsScreen({ onClose, servicosData }) {
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const quillRef = useRef(null);
  const userData = useSelector((state) => state.appReducer.user);


  useEffect(() => {
    if (servicosData) {
      setDescription(servicosData.description || "");
      setName(servicosData.name || "");
    }
  }, [servicosData]);

  const handleSave = () => {
    const updatedServicos = {
      ...servicosData,
      name: name,
      description: description,
    };

    const userName = userData.name; // Supondo que o nome do usuário esteja disponível no estado global

    if (servicosData) {
      dispatch(editServicosList(updatedServicos, userName));
    } else {
      dispatch(addServicos(updatedServicos, userName));
    }

    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const toolbarOptions = [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ];

  const toolbar = window.innerWidth < 600 ? (
    <select
      ref={quillRef}
      onChange={(e) => {
        const format = e.target.value;
        const range = quillRef.current.editor.getSelection();
        if (range) {
          quillRef.current.editor.format(format);
        }
      }}
    >
      <option value="">Format</option>
      <option value="header1">Header 1</option>
      <option value="header2">Header 2</option>
      <option value="bold">Bold</option>
      <option value="italic">Italic</option>
      <option value="underline">Underline</option>
      <option value="strike">Strike</option>
      <option value="blockquote">Blockquote</option>
      <option value="list-ordered">Ordered List</option>
      <option value="list-bullet">Bullet List</option>
      <option value="clean">Clean</option>
    </select>
  ) : (
    <ReactQuill.Toolbar toolbar={toolbarOptions} />
  );
  const editorHeight = window.innerWidth < 600 ? "100%" : "95%";


  return (
    <Div
      className="details-screen-container"
      style={{
        padding: "20px",
        backgroundColor: "#eaeaea",
        borderRadius: 5,
        width: "70%",
      }}
    >
      <Div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ textAlign: "center", margin: "0" }}>
          {servicosData ? "Editar Serviço" : "Novo Serviço"}
        </h2>
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Tópico"
            value={name}
            disabled={!!servicosData}
            variant="outlined"
            onChange={handleChangeName}
          />
        </Grid>
        <Grid item xs={12} style={{ maxHeight: "300px"}}>
  <ReactQuill
    theme="snow"
    style={{ height: editorHeight}}
    value={description}
    onChange={handleChangeDescription}
    modules={{
      toolbar: toolbarOptions,
    }}
    formats={["header1", "header2", "bold", "italic", "underline", "strike", "blockquote", "list-ordered", "list-bullet"]}
  />
</Grid>



        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={!description || !name}
            startIcon={<SaveIcon />}
            onClick={handleSave}
            style={{ marginTop: "20px" }}
          >
            {servicosData ? "Salvar Alterações" : "Salvar"}
          </Button>
        </Grid>
      </Grid>
    </Div>
  );
}

DetailsScreen.propTypes = {
  onClose: PropTypes.func.isRequired,
  servicosData: PropTypes.object,
};

export default DetailsScreen;

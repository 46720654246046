import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import { Div } from "..";
import {
  addAtendimentos,
  loadAtendimentosList,
  deleteAtendimentos,
} from "./action";
import "./Atendimentos.css";
import ReactQuill from "react-quill";
import SendIcon from "@mui/icons-material/Send";

const Atendimentos = () => {
  const dispatch = useDispatch();
  const atendimentos = useSelector(
    (state) => state.atendimentos.atendimentosList
  );

  const [setor, setSetor] = useState("");
  const [assunto, setAssunto] = useState("");
  const [solicitante, setSolicitante] = useState("");
  const [contato, setContato] = useState("");
  const [descricao, setDescricao] = useState("");
  const [assuntosOptions, setAssuntosOptions] = useState([]);

  useEffect(() => {
    if (!atendimentos) {
      dispatch(loadAtendimentosList());
    }
  }, [atendimentos, dispatch]);

  const handleAddAtendimentos = () => {
    const atendimentosData = {
      setor,
      assunto,
      solicitante,
      contato,
      descricao,
    };
    dispatch(addAtendimentos(atendimentosData));
  };

  const setoresOptions = ["Cras", "Creas", "Família Acolhedora", "Habitação"]; // Opções para o menu de setores
  const assuntosCrasOptions = [
    "Oficinas",
    "SCFV ( Serviço de convivência e fortalecimento de vinculos)",
    "Cadastro único",
    "Beneficio Eventual (Funeral)",
    "Beneficio Eventual (Alimentação)",
    "Beneficio Eventual (Auxilio Natalidade)",
    "Beneficio Eventual(Auxilio Vulnerabilidade)",
    "Encaminhamento BPC",
  ];
  const assuntosCreasOptions = [
    "Atendimento Equipe Técnica",
    "Beneficio Eventual (Funeral)",
    "Beneficio Eventual (Alimentação)",
    "Beneficio Eventual (Auxilio Natalidade)",
    "Beneficio Eventual(Auxilio Vulnerabilidade)",
    "Encaminhamento BPC",
  ];
  const assuntosFamiliaAcolhedoraOptions = [
    "Atendimento Equipe Técnica",
    "Beneficio Eventual (Funeral)",
    "Beneficio Eventual (Alimentação)",
    "Beneficio Eventual (Auxilio Natalidade)",
    "Beneficio Eventual(Auxilio Vulnerabilidade)",
    "Encaminhamento BPC",
  ];
  const assuntosHabitacaoOptions = [
    "Axulio Reforma Moradia",
    "Programa Habitacional",
  ];

  useEffect(() => {
    switch (setor) {
      case "Cras":
        setAssuntosOptions(assuntosCrasOptions);
        break;
      case "Creas":
        setAssuntosOptions(assuntosCreasOptions);
        break;
      case "Família Acolhedora":
        setAssuntosOptions(assuntosFamiliaAcolhedoraOptions);
        break;
      case "Habitação":
        setAssuntosOptions(assuntosHabitacaoOptions);
        break;
      default:
        setAssuntosOptions([]);
    }
  }, [setor]);

  return (
    <Div
      style={{
        padding: "20px",
        backgroundColor: "#eaeaea",
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "auto",
        marginBottom: "auto",
        width: '100%'
      }}
    >
      <h2 style={{ textAlign: "center", margin: "0 0 20px 0" }}>
        Registre seu Atendimento
      </h2>
      <Grid container spacing={2} style={{ width: "100%" }}>
        <Grid item xs={6}>
          <Select
            fullWidth
            value={setor}
            onChange={(e) => setSetor(e.target.value)}
            displayEmpty
            placeholder="Selecione o Setor"
          >
            <MenuItem value="" disabled>
              Selecione o Setor
            </MenuItem>
            {setoresOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select
            fullWidth
            value={assunto}
            onChange={(e) => setAssunto(e.target.value)}
            displayEmpty
            placeholder="Selecione o assunto"
          >
            <MenuItem value="" disabled>
              Selecione o Assunto
            </MenuItem>
            {assuntosOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Nome do solicitante"
            variant="outlined"
            value={solicitante}
            onChange={(e) => setSolicitante(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Número para entrar em contato"
            variant="outlined"
            value={contato}
            onChange={(e) => {
              const input = e.target.value;
              const maxLength = 15; // Comprimento máximo do número de telefone
              const regex = /[^0-9]/g; // Para permitir apenas números

              // Remove caracteres não numéricos
              let formattedInput = input.replace(regex, "");

              // Insere os parênteses, espaço e o traço automaticamente
              if (
                formattedInput.length > 0 &&
                formattedInput.charAt(0) !== "("
              ) {
                formattedInput = "(" + formattedInput;
              }
              if (
                formattedInput.length > 3 &&
                formattedInput.charAt(3) !== ")"
              ) {
                formattedInput =
                  formattedInput.slice(0, 3) + ")" + formattedInput.slice(3);
              }
              if (
                formattedInput.length > 4 &&
                formattedInput.charAt(4) !== " "
              ) {
                formattedInput =
                  formattedInput.slice(0, 4) + " " + formattedInput.slice(4);
              }
              if (
                formattedInput.length > 10 &&
                formattedInput.charAt(10) !== "-"
              ) {
                formattedInput =
                  formattedInput.slice(0, 10) + "-" + formattedInput.slice(10);
              }

              // Limita o comprimento máximo do número de telefone
              formattedInput = formattedInput.slice(0, maxLength);

              // Atualiza o valor no estado
              setContato(formattedInput);

              // Chama a função de handleChange passando o número de telefone formatado
              // handleChange(SIGN_UP_CHANGE_PHONE, formattedInput);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ReactQuill
            id="descricao"
            theme="snow"
            value={descricao}
            onChange={setDescricao}
            style={{
              height: "300px",
              marginBottom: "20px",
              borderRadius: "5px",
            }}
            modules={{
              toolbar: [
              ],
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center", marginTop: '0.8rem' }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            disabled= { !descricao || !setor|| !contato || !solicitante || !assunto}
            onClick={handleAddAtendimentos}
          >
            Enviar atendimento
          </Button>
        </Grid>
      </Grid>
    </Div>
  );
};

export default Atendimentos;

import { AVISOS_CHANGE_VALUE } from "../../constants/actionTypes";
import { firebase, getReducer } from "../../utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Função auxiliar para adicionar ao histórico
const addHistoryEntry = (data, userName, action) => {
  const currentDate = new Date().toISOString();
  return {
    ...data,
    modifiedBy: userName,
    modificationDate: currentDate,
    action
  };
};

export function changeVisibleDetailsDialog(show, data = null) {
  return dispatch => {
    dispatch({ type: AVISOS_CHANGE_VALUE, property: 'showDetails', payload: show });
    if (data) {
      dispatch({ type: AVISOS_CHANGE_VALUE, property: 'avisosDetails', payload: data });
      dispatch({ type: AVISOS_CHANGE_VALUE, property: 'edit', payload: true });
    }
    if (!show) setTimeout(() => { dispatch({ type: AVISOS_CHANGE_VALUE, property: 'avisosDetails', payload: { key: '', name: '', description: '' } }) }, 250);
  }
}

export function loadAvisosList() {
  return dispatch => {
    firebase.firestore.collection('avisos').get()
      .then(snap => {
        const data = snap.docs.map(doc => doc.data())
        dispatch({ type: AVISOS_CHANGE_VALUE, property: 'avisosList', payload: data })
      })
      .catch(error => {
        console.log("loadAvisosList", error)
      })
  }
}

export function deleteAvisos(key) {
  return dispatch => {
    firebase.firestore.doc(`avisos/${key}`).delete()
      .then(() => {
        const avisosList = getReducer("avisos", "avisosList")
        let updateList = JSON.parse(JSON.stringify(avisosList))
        updateList = updateList.filter(avisos => avisos.key !== key)
        dispatch({ type: AVISOS_CHANGE_VALUE, property: 'avisosList', payload: updateList })
        toast.success('Aviso deletado com sucesso!');
      })
      .catch(error => {
        console.log("deleteAvisos", error)
        toast.error('Erro ao deletar aviso.');
      })
  }
}

export function editAvisosList(data, userName) {
  return dispatch => {
    const currentDate = new Date().toISOString();
    const updatedAvisos = {
      ...data,
      modifiedBy: userName,
      modificationDate: currentDate
    };

    firebase.firestore.doc(`avisos/${data.key}`).update(updatedAvisos)
      .then(() => {
        const historyData = addHistoryEntry(data, userName, 'Editou');
        firebase.firestore.collection(`avisos/${data.key}/history`).doc(currentDate).set(historyData);

        const avisosList = getReducer("avisos", "avisosList")
        let updateList = JSON.parse(JSON.stringify(avisosList))
        updateList = updateList.map(avisos => avisos.key === data.key ? updatedAvisos : avisos)
        dispatch({ type: AVISOS_CHANGE_VALUE, property: 'avisosList', payload: updateList })
        dispatch(changeVisibleDetailsDialog(false))
        toast.success('Aviso editado com sucesso!');
      })
      .catch(error => {
        console.log("editAvisosList", error)
        toast.error('Erro ao editar aviso.');
      })
  }
}

export function addAvisos(data, userName) {
  return (dispatch, getState) => {
    const refDoc = firebase.firestore.collection('avisos').doc();
    const newDocId = refDoc.id;

    const name = data.name !== undefined ? data.name : 'defaultName';
    const description = data.description !== undefined ? data.description : 'defaultDescription';

    const dataAvisos = {
      ...data,
      key: newDocId,
      name: name,
      description: description,
      createdBy: userName,
      creationDate: new Date().toISOString()
    };

    refDoc.set(dataAvisos)
      .then(() => {
        const currentAvisosList = getState().avisos.avisosList;
        const updatedAvisosList = [...currentAvisosList, dataAvisos];
        dispatch({ type: AVISOS_CHANGE_VALUE, property: 'avisosList', payload: updatedAvisosList });

        const historyData = addHistoryEntry(dataAvisos, userName, 'Adicionou');
        firebase.firestore.collection(`avisos/${newDocId}/history`).doc(dataAvisos.creationDate).set(historyData);

        dispatch(changeVisibleDetailsDialog(false));
        toast.success('Aviso adicionado com sucesso!');
      })
      .catch(error => {
        console.log("addAvisos", error);
        toast.error('Erro ao adicionar aviso.');
      });
  };
}
export const loadAvisosHistory = (key) => async (dispatch) => {
  try {
    const historyRef = firebase.firestore.collection('avisos').doc(key).collection('history');
    const historySnapshot = await historyRef.orderBy('modificationDate', 'desc').get();

    const historyData = historySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    dispatch({
      type: 'LOAD_AVISOS_HISTORY_SUCCESS',
      payload: historyData
    });
  } catch (error) {
    console.error("Error loading history: ", error);
    dispatch({
      type: 'LOAD_AVISOS_HISTORY_FAILURE',
      payload: error
    });
  }
};
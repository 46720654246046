import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  deleteButton: {
    backgroundColor: "#f44336", /* Red */
    color: "white",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#d32f2f", /* Darker Red */
    },
  },
}));

function DialogDelete(props) {
  const { onClose, deleteServicos, dele } = props;
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={dele} maxWidth="xs" classes={{ paper: classes.dialogPaper }}>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Você realmente deseja deletar esse tópico?
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth size="medium" variant="contained" onClick={onClose}>
            Não
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth size="medium" variant="contained" className={classes.deleteButton} onClick={deleteServicos}>
            Sim
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

DialogDelete.propTypes = {
  onClose: PropTypes.func.isRequired,
  dele: PropTypes.bool.isRequired,
  deleteServicos: PropTypes.func.isRequired,
};

export default DialogDelete;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { loadServicosHistory } from "../action";
import { Typography, Box, Grid, List, ListItem, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import InfoIcon from '@mui/icons-material/Info';
import EventIcon from '@mui/icons-material/Event';
import Div from "../../Div/Div";

function HistoryScreen({ onClose, servicosData }) {
  const dispatch = useDispatch();
  const historyData = useSelector((state) => state.servicos.servicosHistory);

  useEffect(() => {
    if (servicosData) {
      dispatch(loadServicosHistory(servicosData.key));
    }
  }, [servicosData, dispatch]);

  const truncateDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Div
      style={{
        padding: "20px",
        backgroundColor: "#eaeaea",
        borderRadius: 5,
        width: "90%",
        position: "relative",
        zIndex: 1000,
      }}
    >
      <Div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ textAlign: "center", margin: "0" }}>
          Histórico de Modificações
        </h2>
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Div>
      <Grid container spacing={2}>
        {historyData && (
          <Grid item xs={12}>
            <List style={{ display: 'flex', flexDirection: 'column' }}>
              {historyData.map((entry, index) => (
                <ListItem
                  key={index}
                  style={{
                    border: '1px solid #ddd',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    padding: '10px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography variant="body1">
                    {`Modificado por: ${entry.modifiedBy} em ${new Date(entry.modificationDate).toLocaleString()}`}
                  </Typography>
                  <Typography variant="body2">
                    {`Ação: ${entry.action}`}
                  </Typography>
                  <Typography variant="body2">
                  </Typography>
                  <Typography variant="body2">
                    Título: {entry.name}
                  </Typography>
                  <Typography>Descrição:</Typography>
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: truncateDescription(entry.description, 100),
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        )} 
      </Grid>
    </Div>
  );
}

HistoryScreen.propTypes = {
  onClose: PropTypes.func.isRequired,
  servicosData: PropTypes.object.isRequired,
};

export default HistoryScreen;

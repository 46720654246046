import { 
    SERVICOS_CHANGE_VALUE, 
    SERVICOS_CHANGE_CHILD_VALUE, 
    SERVICOS_CHANGE_USERDATA_CHILD_VALUE,
    LOAD_SERVICOS_HISTORY_SUCCESS,
    LOAD_SERVICOS_HISTORY_FAILURE
  } from '../../constants/actionTypes';
  
  const INITIAL_STATE = {
      showDetails: false,
      servicosDetails: {
          key: '', name: '', description: '',
      },
      
      edit: false,
      servicosHistory: [], // Adicionando o estado do histórico de avisos

  };
  
  const servicos = (state = INITIAL_STATE, action) => {
      switch(action.type) {
          case SERVICOS_CHANGE_VALUE:
              return {
                  ...state,
                  [action.property]: action.payload,
              };
          case SERVICOS_CHANGE_CHILD_VALUE:
              return {
                  ...state,
                  [action.key]: {
                      ...state[action.key],
                      [action.property]: action.payload,
                  },
              };
          case SERVICOS_CHANGE_USERDATA_CHILD_VALUE:
              return {
                  ...state,
                  servicosDetails: {
                      ...state.servicosDetails,
                      [action.key]: {
                          ...state.servicosDetails[action.key],                   
                          [action.property]: action.payload,
                      }
                  }
              };
              case LOAD_SERVICOS_HISTORY_SUCCESS:
              return {
                  ...state,
                  servicosHistory: action.payload,
              };
          case LOAD_SERVICOS_HISTORY_FAILURE:
              return {
                  ...state,
                  servicosHistory: [],
              };
          default:
              return state;
      }
  }
  
  export default servicos;
  
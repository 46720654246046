/* app */
export const APP_CHANGE_VALUE = 'APP_CHANGE_VALUE'

/* cadastro */
export const SIGN_UP_CHANGE_NAME = 'SIGN_UP_CHANGE_NAME'
export const SIGN_UP_CHANGE_LAST_NAME = 'SIGN_UP_CHANGE_LAST_NAME'
export const SIGN_UP_CHANGE_EMAIL = 'SIGN_UP_CHANGE_EMAIL'
export const SIGN_UP_CHANGE_PHONE = 'SIGN_UP_CHANGE_PHONE'
export const SIGN_UP_CHANGE_PASSWORD = 'SIGN_UP_CHANGE_PASSWORD'
export const SIGN_UP_CHANGE_JOB_TITLE = 'SIGN_UP_CHANGE_JOB_TITLE' // Adicionado
export const SIGN_UP_CHANGE_CPF = 'SIGN_UP_CHANGE_CPF' // Adicionado
export const SIGN_UP_CHANGE_ORGAO = 'SIGN_UP_CHANGE_ORGAO' // Adicionado
export const SIGN_UP_CHANGE_DATANASCIMENTO = 'SIGN_UP_CHANGE_DATANASCIMENTO' // Adicionado
export const SIGN_UP_CHANGE_SHOWDIALOG = 'SIGN_UP_CHANGE_SHOWDIALOG'
export const SHOW_ALERT_MESSAGE = 'SHOW_ALERT_MESSAGE';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_CHANGE_CONFIRMPASSWORD = 'SIGN_UP_CHANGE_CONFIRMPASSWORD';

/* login */
export const SIGN_IN_CHANGE_VALUE = 'SIGN_IN_CHANGE_VALUE'
export const SIGN_IN_CLEAN = 'SIGN_IN_CLEAN'
export const SIGN_IN_LOADING = 'SIGN_IN_LOADING'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'

/* home */
export const HOME_CHANGE_VALUE = 'HOME_CHANGE_VALUE'
export const HOME_CHANGE_CHILD_VALUE = 'HOME_CHANGE_CHILD_VALUE'
export const HOME_CHANGE_USERDATA_CHILD_VALUE = "HOME_CHANGE_USERDATA_CHILD_VALUE"

/* servicos */
export const SERVICOS_CHANGE_VALUE = 'SERVICOS_CHANGE_VALUE'
export const SERVICOS_CHANGE_CHILD_VALUE = 'SERVICOS_CHANGE_CHILD_VALUE'
export const SERVICOS_CHANGE_USERDATA_CHILD_VALUE = "SERVICOS_CHANGE_USERDATA_CHILD_VALUE"
export const LOAD_SERVICOS_HISTORY_SUCCESS = "LOAD_SERVICOS_HISTORY_SUCCESS"
export const LOAD_SERVICOS_HISTORY_FAILURE = "LOAD_SERVICOS_HISTORY_FAILURE"



/* atendimento */
export const ATENDIMENTOS_CHANGE_VALUE = 'ATENDIMENTOS_CHANGE_VALUE'
export const ATENDIMENTOS_CHANGE_CHILD_VALUE = 'ATENDIMENTOS_CHANGE_CHILD_VALUE'
export const ATENDIMENTOS_CHANGE_USERDATA_CHILD_VALUE = "ATENDIMENTOS_CHANGE_USERDATA_CHILD_VALUE"

/* avisos */
export const AVISOS_CHANGE_VALUE = 'AVISOS_CHANGE_VALUE'
export const AVISOS_CHANGE_CHILD_VALUE = 'AVISOS_CHANGE_CHILD_VALUE'
export const AVISOS_CHANGE_USERDATA_CHILD_VALUE = "AVISO_CHANGE_USERDATA_CHILD_VALUE"
export const LOAD_AVISOS_HISTORY_SUCCESS = "LOAD_AVISOS_HISTORY_SUCCESS"
export const LOAD_AVISOS_HISTORY_FAILURE = "LOAD_AVISOS_HISTORY_FAILURE"


/* atendimento setor */
export const ATENDIMENTOSETOR_CHANGE_VALUE = 'ATENDIMENTOSETOR_CHANGE_VALUE'
export const ATENDIMENTOSETOR_CHANGE_CHILD_VALUE = 'ATENDIMENTOSETOR_CHANGE_CHILD_VALUE'
export const ATENDIMENTOSETOR_CHANGE_USERDATA_CHILD_VALUE = "ATENDIMENTOSETOR_CHANGE_USERDATA_CHILD_VALUE"
export const ATENDIMENTOSETOR_SET_DETAILS = "ATENDIMENTOSETOR_SET_DETAILS"

/* galeria */
export const GALERIAS_CHANGE_VALUE = 'GALERIAS_CHANGE_VALUE'
export const GALERIAS_CHANGE_CHILD_VALUE = 'GALERIAS_CHANGE_CHILD_VALUE'
export const GALERIAS_CHANGE_USERDATA_CHILD_VALUE = "GALERIAS_CHANGE_USERDATA_CHILD_VALUE"

/* user */
export const USER_CHANGE_VALUE = 'USER_CHANGE_VALUE'
export const USERS_CHANGE_VALUE = 'USERS_CHANGE_VALUE'
export const USERS_CHANGE_CHILD_VALUE = 'USERS_CHANGE_CHILD_VALUE'
export const USERS_CHANGE_USERDATA_CHILD_VALUE = "USERS_CHANGE_USERDATA_CHILD_VALUE"
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const USERS_CHANGE_USERDATA = 'USERS_CHANGE_USERDATA'




import { 
    ATENDIMENTOS_CHANGE_VALUE, 
    ATENDIMENTOS_CHANGE_CHILD_VALUE, 
    ATENDIMENTOS_CHANGE_USERDATA_CHILD_VALUE,
  } from '../../constants/actionTypes';
  
  const INITIAL_STATE = {
      showDetails: false,
      atendimentosDetails: {
          key: '',  setor: '',
          assunto: '',
          solicitante: '',
          contato: '',
          descricao: ''
      },
      
      edit: false,
  };

  
  
  const atendimentos = (state = INITIAL_STATE, action) => {
      switch(action.type) {
          case ATENDIMENTOS_CHANGE_VALUE:
              return {
                  ...state,
                  [action.property]: action.payload,
              };
          case ATENDIMENTOS_CHANGE_CHILD_VALUE:
              return {
                  ...state,
                  [action.key]: {
                      ...state[action.key],
                      [action.property]: action.payload,
                  },
              };
          case ATENDIMENTOS_CHANGE_USERDATA_CHILD_VALUE:
              return {
                  ...state,
                  atendimentosDetails: {
                      ...state.atendimentosDetails,
                      [action.key]: {
                          ...state.atendimentosDetails[action.key],                   
                          [action.property]: action.payload,
                      }
                  }
              };
          default:
              return state;
      }
  }
  
  export default atendimentos;
  
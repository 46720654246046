import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { Box, Grid, Menu, MenuItem, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Div from "../../Div/Div";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import InfoIcon from "@mui/icons-material/Info";
import EventIcon from "@mui/icons-material/Event";

function DetailsScreen({ onClose, atendimentosSetorData }) {
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("PriorityHighIcon");
  const [category, setCategory] = useState("Urgente");
  const [iconSelected, setIconSelected] = useState(false);

  useEffect(() => {
    if (atendimentosSetorData) {
      setDescription(atendimentosSetorData.description || "");
      setName(atendimentosSetorData.name || "");
      setCategory(atendimentosSetorData.category || "Urgente");
      setIcon(getIconFromCategory(atendimentosSetorData.category || "Urgente"));
      setIconSelected(true);
    }
  }, [atendimentosSetorData]);

  const getIconFromCategory = (category) => {
    switch (category) {
      case "Urgente":
        return "PriorityHighIcon";
      case "Informativo":
        return "InfoIcon";
      case "Eventos":
        return "EventIcon";
      default:
        return "PriorityHighIcon";
    }
  };

  const getTextFromCategory = (category) => {
    switch (category) {
      case "Urgente":
        return "Urgente";
      case "Informativo":
        return "Informativo";
      case "Eventos":
        return "Eventos";
      default:
        return "Urgente";
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeIcon = (category) => {
    setIcon(getIconFromCategory(category));
    setCategory(category);
    setIconSelected(true);
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };
  const editorHeight = window.innerWidth < 600 ? "78%" : "95%";

  function formatTimestamp(timestamp) {
    // Assuming timestamp is in milliseconds, you can convert it to a Date object
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    // Format the date as needed, for example:
    return date.toLocaleString(); // Adjust this based on your requirements
  }
  
  return (
    <Div
      style={{
        padding: "20px",
        backgroundColor: "#eaeaea",
        borderRadius: 5,
        width: "90%", // Alterado para ocupar 100% da largura
        position: "relative",
        zIndex: 1000,
      }}
    >
      <Div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            margin: "0",
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          Atendimento de {atendimentosSetorData.solicitante}
        </h2>
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Div>

      <Box
  style={{
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px",
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  }}
>
        <Grid container>
          <Grid item xs={12} md={10}>
            <Typography>
              {atendimentosSetorData.setor} - {atendimentosSetorData.assunto}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            <Typography>
              {atendimentosSetorData.solicitante} -{" "}
              {atendimentosSetorData.contato}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
  <Grid item xs={12} md={10}>
    <Typography>{atendimentosSetorData.dataEnvio}</Typography>
  </Grid>
</Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: truncateDescription(
                  atendimentosSetorData.descricao,
                  100
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Div>
  );
}

DetailsScreen.propTypes = {
  onClose: PropTypes.func.isRequired,
  avisosData: PropTypes.object,
};

export default DetailsScreen;

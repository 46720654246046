import { 
    AVISOS_CHANGE_VALUE, 
    AVISOS_CHANGE_CHILD_VALUE, 
    AVISOS_CHANGE_USERDATA_CHILD_VALUE,
    LOAD_AVISOS_HISTORY_SUCCESS,
    LOAD_AVISOS_HISTORY_FAILURE
  } from '../../constants/actionTypes';
  
  const INITIAL_STATE = {
      showDetails: false,
      avisosDetails: {
          key: '', name: '', description: '',
      },
      edit: false,
      avisosHistory: [], // Adicionando o estado do histórico de avisos
  };
  
  const avisos = (state = INITIAL_STATE, action) => {
      switch(action.type) {
          case AVISOS_CHANGE_VALUE:
              return {
                  ...state,
                  [action.property]: action.payload,
              };
          case AVISOS_CHANGE_CHILD_VALUE:
              return {
                  ...state,
                  [action.key]: {
                      ...state[action.key],
                      [action.property]: action.payload,
                  },
              };
          case AVISOS_CHANGE_USERDATA_CHILD_VALUE:
              return {
                  ...state,
                  avisosDetails: {
                      ...state.avisosDetails,
                      [action.key]: {
                          ...state.avisosDetails[action.key],                   
                          [action.property]: action.payload,
                      }
                  }
              };
          case LOAD_AVISOS_HISTORY_SUCCESS:
              return {
                  ...state,
                  avisosHistory: action.payload,
              };
          case LOAD_AVISOS_HISTORY_FAILURE:
              return {
                  ...state,
                  avisosHistory: [],
              };
          default:
              return state;
      }
  }
  
  export default avisos;
  
import { 
    ATENDIMENTOSETOR_CHANGE_VALUE, 
    ATENDIMENTOSETOR_CHANGE_CHILD_VALUE, 
    ATENDIMENTOSETOR_CHANGE_USERDATA_CHILD_VALUE,
    ATENDIMENTOSETOR_SET_DETAILS // Import the new action type
  } from '../../constants/actionTypes';
  
  const INITIAL_STATE = {
    showDetails: false,
    atendimentosSetorDetails: {
      key: '', name: '', description: '',
    },
    edit: false,
    atendimentosList: [], // Add the list to the initial state
  };
  
  const atendimentosSetor = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case ATENDIMENTOSETOR_CHANGE_VALUE:
        return {
          ...state,
          [action.property]: action.payload,
        };
      case ATENDIMENTOSETOR_CHANGE_CHILD_VALUE:
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            [action.property]: action.payload,
          },
        };
      case ATENDIMENTOSETOR_CHANGE_USERDATA_CHILD_VALUE:
        return {
          ...state,
          atendimentosSetorDetails: {
            ...state.atendimentosSetorDetails,
            [action.key]: {
              ...state.atendimentosSetorDetails[action.key],                   
              [action.property]: action.payload,
            }
          }
        };
      case ATENDIMENTOSETOR_SET_DETAILS:
        return {
          ...state,
          showDetails: true,
          atendimentosSetorDetails: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default atendimentosSetor;
  
import { ATENDIMENTOSETOR_CHANGE_VALUE, ATENDIMENTOSETOR_SET_DETAILS } from "../../constants/actionTypes";
import { firebase } from "../../utils";

export function loadAtendimentosSetorList() {
  return (dispatch) => {
    firebase.firestore.collection('atendimentos').get()
      .then((snap) => {
        const data = snap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        dispatch({ type: ATENDIMENTOSETOR_CHANGE_VALUE, property: 'atendimentosList', payload: data });
      })
      .catch((error) => {
        console.log("atendimentosList", error);
      });
  };
}

export function changeVisibleDetailsDialog(show, data = null) {
  return (dispatch) => {
    dispatch({ type: ATENDIMENTOSETOR_CHANGE_VALUE, property: 'showDetails', payload: show });
    if (data) {
      dispatch({ type: ATENDIMENTOSETOR_CHANGE_VALUE, property: 'atendimentosSetorDetails', payload: data });
      dispatch({ type: ATENDIMENTOSETOR_CHANGE_VALUE, property: 'edit', payload: true });
    }
    if (!show) {
      setTimeout(() => {
        dispatch({ type: ATENDIMENTOSETOR_CHANGE_VALUE, property: 'atendimentosSetorDetails', payload: { key: '', name: '', description: '' } });
      }, 250);
    }
  };
}

export function loadAtendimentoDetails(atendimento) {
  return (dispatch) => {
    dispatch({ type: ATENDIMENTOSETOR_SET_DETAILS, payload: atendimento });
    dispatch(changeVisibleDetailsDialog(true, atendimento));
  };
}

import { 
    GALERIAS_CHANGE_VALUE, 
    GALERIAS_CHANGE_CHILD_VALUE, 
    GALERIAS_CHANGE_USERDATA_CHILD_VALUE,
  } from '../../constants/actionTypes';
  
  const INITIAL_STATE = {
      showDetails: false,
      galeriasDetails: {
          key: '', name: '', description: '',
      },
      
      edit: false,
  };
  
  const galeria = (state = INITIAL_STATE, action) => {
      switch(action.type) {
          case GALERIAS_CHANGE_VALUE:
              return {
                  ...state,
                  [action.property]: action.payload,
              };
          case GALERIAS_CHANGE_CHILD_VALUE:
              return {
                  ...state,
                  [action.key]: {
                      ...state[action.key],
                      [action.property]: action.payload,
                  },
              };
          case GALERIAS_CHANGE_USERDATA_CHILD_VALUE:
              return {
                  ...state,
                  galeriasDetails: {
                      ...state.galeriasDetails,
                      [action.key]: {
                          ...state.galeriasDetails[action.key],                   
                          [action.property]: action.payload,
                      }
                  }
              };
          default:
              return state;
      }
  }
  
  export default galeria;
  
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Fab,
  Container,
  Box,
  Grid,
  IconButton,
  Pagination,
} from "@mui/material";
import { Div } from "..";
import DialogDelete from "./Details/DialogDelete";
import AddIcon from "@mui/icons-material/Add";
import DetailsScreen from "./Details/DetailsScreen";
import {
  addServicos,
  loadServicosList,
  deleteServicos,
  loadServicosHistory,
} from "./action";
import "./Servicos.css";
import HistoryScreen from "./Details/HistoryScreen";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Servicos = () => {
  const dispatch = useDispatch();
  const servicos = useSelector((state) => state.servicos.servicosList);

  const [showDetails, setShowDetails] = useState(false);
  const [selectedServicos, setSelectedServicos] = useState(null);
  const [dele, setDele] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [historyServicos, setHistoryServicos] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleDele = (servicos) => {
    setDele(true);
    setSelectedServicos(servicos);
  };

  const handleDelete = () => {
    setDele(false);
    setSelectedServicos(null);
  };

  const deleteProduct = () => {
    dispatch(deleteServicos(selectedServicos.key));
    handleDelete();
    toast.info("Serviço deletado com sucesso!");
  };

  useEffect(() => {
    if (!servicos) {
      dispatch(loadServicosList());
    }
  }, [servicos, dispatch]);

  const handleOpen = () => {
    setShowDetails(true);
    setSelectedServicos(null);
  };

  const handleHistory = (servicos) => {
    setShowHistory(true);
    setHistoryServicos(servicos);
    dispatch(loadServicosHistory(servicos.key));
  };

  const handleEditServicos = (servicos) => {
    setShowDetails(true);
    setSelectedServicos(servicos);
  };

  const handleAddServicos = (servicosData) => {
    dispatch(addServicos(servicosData));
    setShowDetails(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const userData = useSelector((state) => state.appReducer.user);
  const isAdm = userData?.accessControl?.adm;
  const readyOnly = userData?.accessControl?.readyOnly;
  const userName = userData?.name;

  // Paginação dos serviços
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentServicos = servicos
    ? servicos.slice(startIndex, startIndex + itemsPerPage)
    : [];

  return (
    <Div className="servico-container">
      <Div>
        <Div elevation={6} className="servico-paper">
          <Typography variant="h1" className="text-center mb-0">
            Servicos oferecidos
          </Typography>
        </Div>
      </Div>
      <Container>
        <ToastContainer />
        {showDetails && (
          <div className="details-screen-overlay">
            <DetailsScreen
              onClose={() => setShowDetails(false)}
              servicosData={selectedServicos}
              onSave={handleAddServicos}
            />
          </div>
        )}
        {showHistory && (
          <Div className="details-screen-overlay">
            <HistoryScreen
              onClose={() => setShowHistory(false)}
              servicosData={historyServicos}
            />
          </Div>
        )}
        {!showDetails && !showHistory && (
          <>
            <Grid
              container
              spacing={4}
              className="servico-grid"
              justifyContent="flex-start"
            >
              {currentServicos &&
                currentServicos.map((servico, index) => (
                  <Grid item xs={12} key={index} className="servico-item-grid">
                    <Div elevation={3} className="servico-item" color="#000">
                      <Grid container alignItems="center">
                        <Grid item xs={8}>
                          <Typography variant="h6">{servico.name}</Typography>
                        </Grid>

                        {isAdm || readyOnly ? (
                          <Grid item xs={4} container justifyContent="flex-end">
                            <IconButton
                              onClick={() => handleEditServicos(servico)}
                              style={{ color: "black" }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDele(servico)}
                              style={{ color: "black" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleHistory(servico)}
                              style={{ color: "black" }}
                            >
                              <HistoryIcon />
                            </IconButton>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid style={{ marginTop: "1.5rem" }}>
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: servico.description,
                          }}
                        />
                      </Grid>
                    </Div>
                  </Grid>
                ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={Math.ceil((servicos ? servicos.length : 0) / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
            <Box mb={11} />
            <DialogDelete
              dele={dele}
              onClose={handleDelete}
              deleteServicos={deleteProduct}
              servicos={selectedServicos}
            />

            {isAdm || readyOnly ? (
              <Box className="fab-container">
                <Fab color="secondary" aria-label="add" onClick={handleOpen}>
                  <AddIcon />
                </Fab>
              </Box>
            ) : null}
          </>
        )}
      </Container>
    </Div>
  );
};

export default Servicos;
